import React from 'react'
import MainInner from '../components/MainInner'
import Seo from '../components/Seo'

export default function Faq() {
  return (
    <>
      <Seo title="FAQ" />

      <MainInner>
        <h2>FAQ</h2>
        <h3>Do you offer discounts?</h3>
        <p>
          Depends on what you mean by discount. I use flexible pricing so you
          can choose a price that works for you. If you need a course for less
          than the lowest amount, reach out to me personally. I would like to
          understand your situation better. You can DM me on{' '}
          <a href="https://twitter.com/kyleshevlin">Twitter</a> or send me an
          email at{' '}
          <a href="mailto:kyle@kyleshevlin.com">kyle@kyleshevlin.com</a>.
        </p>

        <h3>Do you offer refunds?</h3>
        <p>
          If you are sincerely dissatisfied with the product, sure. Contact me
          and we will sort it out.
        </p>

        <h3>When are more courses coming?</h3>
        <p>
          I'm not certain, but hopefully soon. I built this platform so that I
          could author and publish at a pace that was sustainable for me. My
          hope is that leads to roughly a course per quarter.
        </p>
      </MainInner>
    </>
  )
}
